import React, { FunctionComponent } from "react";

import { User } from "../../../../../dtos/user";
import { LargeText } from "../../../../atoms/LargeText";
import { MediumText } from "../../../../atoms/MediumText";
import { HCenterStack } from "../../../../layout/HStack";

const errorTitleMapping: Record<string, string> = {
  "verify-credentials": "Credentials",
  "retrieve-accounts": "Accounts",
  "retrieve-transactions": "Transactions",
};

const formatter = new Intl.ListFormat("en", {
  style: "long",
  type: "conjunction",
});

type OnboardingMessageProps = {
  user?: User | null;
};
export const OnboardingMessage: FunctionComponent<OnboardingMessageProps> = ({
  user,
}) => {
  if (!user) {
    return <></>;
  }

  const { basiqJobStatus } = user;
  const jobStatusList = basiqJobStatus ?? [];

  const failedJobs: IBasiqJobStatus[] = jobStatusList.filter(
    ({ status }) => status === "failed"
  );

  const failedJobActionMapList: Array<{ title: string; action: string }> =
    failedJobs.map(({ title, action }) => ({
      title: errorTitleMapping[title],
      action,
    }));

  const title = !failedJobs.length
    ? "Welcome to BoostYourSuper Round-Up!"
    : `Oops, ${formatter.format(
      failedJobActionMapList.map(({ title }) => title)
    )} Error Occurred!`;

  const description1 = `We will guide you through the last steps to connect your Round-Up accounts.`;
  const description2 =
    "You'll connect to your bank accounts using your internet banking credentials and select which accounts you would like to use for Round-Ups.";

  const descriptionArray = [
    ...new Set(failedJobActionMapList.map(({ action }) => action)),
  ];

  return (
    <>
      <HCenterStack>
        <LargeText center style={{ fontSize: 25 }}>{title}</LargeText>
      </HCenterStack>

      {!descriptionArray.length ? (
        <>
          <MediumText center style={{ fontSize: 16, padding: 10 }}>{description1}</MediumText>
          <MediumText center style={{ fontSize: 16, padding: 10 }}>{description2}</MediumText>
        </>
      ) : (
        descriptionArray.map((description, index) => (
          <MediumText key={index}>{description}</MediumText>
        ))
      )}
    </>
  );
};
